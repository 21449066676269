<template>
	<div>
		<v-card class="mb-3">
			<v-container>
				<v-row>
					<v-col>
						<s-date
							label="Fecha Inicio"
							v-model="filter.cDateInitial"
						></s-date>
					</v-col>
					<v-col>
						<s-date
							label="Fecha Fin"
							v-model="filter.cDateFin"
						></s-date>
					</v-col>
				</v-row>
			</v-container>
		</v-card>
		<v-row>
			<v-col lg="6" md="6" sm="12" xs="12">
				<v-card>
					<weighed-category :filter="filter"/>
				</v-card>
			</v-col>
			<v-col lg="6" md="6" sm="12" xs="12">
				<v-card>
					<!-- <category :filter="filter"/> -->
				</v-card>
			</v-col>
		</v-row>
		<v-row>
			<v-col lg="6" md="6" sm="12" xs="12">
				<v-card>
					<!-- <category/> -->
				</v-card>
			</v-col>
			<v-col lg="6" md="6" sm="12" xs="12">
				<v-card>
					<!-- <category/> -->
				</v-card>
			</v-col>
		</v-row>
	</div>
</template>

<script>
	import WeighedCategory from './WeighedCategory.vue'

	export default {
		components: {
			WeighedCategory
		},

		watch: {
			"filter.cDateInitial"(newValue, oldValue) {
				console.log('ssdsdfsd');
			}
		},

		data() {
			return {
				filter: []
			}
		},

		mounted () {
			;
		},
	}
</script>