import Service from "../Service";
const resource = "dashboardmaturation/";

export default {

    weightbytypeselection(obj, requestID) {
        return Service.post(resource + "weightbytypeselection", obj, {
            params: { requestID: requestID },
        });
    },

};