<template>
	<div>
		<highcharts :options="chartOptions"></highcharts>
	</div>
</template>

<script>
	import {Chart} from 'highcharts-vue';
	import _DashboardMaturationService from '@/services/FrozenProduction/FrzDashboardMaturationService.js';

	export default {
		components: {
			highcharts: Chart 
		},

		props: {
			filter: null,
		},

		data() { 
			return {
				dataSearch : {},
				ycategories : [],
				xdataseries : [],
				datasets : [{
						data: [3, 6, 1, 2, 6]
					},
				],
				title: 'Peso por Fondo/Flota ',
				chartOptions: {
					chart: {
						type: 'bar',
						marginLeft: 100
					},
					title: {
						text: 'PESO POR FONDO/FLOTA (Tn)',
						align: 'left'
					},
					credits: {
						enabled: false
					},
					xAxis: {
						categories: this.ycategories,
						labels: {
							enabled: true
						}
					},
					yAxis: {
						allowDecimals: false,
						title: {
							text: null
						},
						min: 0,
						max: 50
					},
					legend: {
						enabled: false
					},
					series: [
						{
							type: 'bar',
							name: 'Peso Tn',
							data: this.xdataseries
						}
					],
				},
			}
		},

		watch: {
			"filter.cDateInitial"() {
				this.Initialize();
			},
			"filter.cDateFin"(newValue, oldValue) {
				this.Initialize();
			}
		},

		methods: {
			Initialize()
			{
				let obj = {
					cDateInitial : this.filter.cDateInitial,
					cDateFin : this.filter.cDateFin,
				}
				_DashboardMaturationService
				.weightbytypeselection(obj, this.$fun.getUserID())
				.then(resp => {
					if(resp.status == 200){
						this.dataSearch = resp.data;
						this.dataSearch.forEach(element => {
							this.ycategories.push(element.TypeSelectionFrozenName);
							this.xdataseries.push(element.LlpWeightOriginalNet);
						});
						console.log('dddddd ', this.ycategories);
					}
				})
			}
		},

		mounted () {
			this.Initialize();
		},
	}
</script>

<style scoped>

</style>